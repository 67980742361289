.settings-container {
  border-top-left-radius: 10px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.settings-container .setting-toggle-container {
  height: 90px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0px 20px;
}

.settings-container .setting-toggle-container button {
  max-width: 150px;
  background-color: #fff;
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  margin-right: 20px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  padding: 11px 16px;
  border: 1px solid #009B77;
  color: black;
}

.settings-container .plan-details {
  height: calc(100vh - 200px);
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.settings-container .plan-details h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #000;
  text-transform: capitalize;
}

.settings-container .plansParaOne {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.settings-container .plansParaOne p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #000;
  margin-right: 77px;
}

.settings-container .plansParaOne button {
  margin-right: 84px;
  min-width: 75px;
  height: 60px;
  background: #f8f8f9;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.settings-container .plansParaOne button:hover {
  background: rgba(0, 155, 119, 0.05);
  color: #009B77;
}

.settings-container .upgradeBtn {
  width: 250px;
  background-color: #ff4359;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #fff;
  height: 50px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
}

.settings-container .setting-toggle-container .active {
  background-color: #009B77;
  color: #fff;
}