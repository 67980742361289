.center-dashboard .search-container {
  height: 160px;
  background: #fff;
  border-radius: 10px;
  padding: 0 30px;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-dashboard .search-container p {
  font-weight: 500;
  font-size: 24px;
}

.center-dashboard .search-container span {
  font-weight: 300;
  font-size: 18px;
}

.center-dashboard .search-container .input-container {
  width: 200px;
  height: 50px;
  position: relative;
  margin-left: 0px;
}

.center-dashboard .search-container input {
  width: 200px;
  height: 50px;
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.05));
  border-radius: 5px;
  border: 3px solid #009B77;
  padding-left: 30px;
  padding-right: 20px;
}

.center-dashboard .search-container img {
  position: absolute;
}

.center-dashboard .search-container .search {
  left: 8px;
  top: 10px;
}

.center-dashboard .search-container .cross {
  right: 8px;
  top: 15px;
  cursor: pointer;
}

/* quotes info */
.center-dashboard .quotes-info {
  height: 90px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px 30px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.center-dashboard .quotes-info span {
  font-weight: 500;
  font-size: 18px;
  margin-right: 8px;
  color: #000;
}

.center-dashboard .quotes-info .line {
  height: 100%;
  background-color: rgba(196, 196, 196, 0.5);
  width: 1px;
}

/* popular quotes */
.center-dashboard .popular-quotes {
  height: calc(100vh - 380px);
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px 10px 0 0;
  padding: 10px 20px;
  overflow: scroll;
}

.center-dashboard .popular-quotes .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

.center-dashboard .popular-quotes .head span:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
}

.center-dashboard .popular-quotes .card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(248, 248, 249, 0.5);
  border-radius: 10px;
  padding: 20px;
  font-weight: 500;
  font-size: 16px;
  width: 150px;
  height: 150px;
  position: relative;
}

.center-dashboard .popular-quotes .card img {
  width: 70%;
  height: 70%;
}

.center-dashboard .popular-quotes .card:hover {
  background: rgba(0, 155, 119, 0.05);
}

.center-dashboard .popular-quotes .card .download {
  display: none;
}

.center-dashboard .popular-quotes .card:hover .download {
  width: 80%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.center-dashboard .popular-quotes .card .download svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.center-dashboard .popular-quotes .card .download svg:hover path {
  stroke: #000000;
}

.center-dashboard .card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.center-dashboard .card-container .card {
  width: 100%;
  background: rgba(248, 248, 249, 0.5);
  border-radius: 10px;
  cursor: pointer;
}

.center-dashboard .card-container .card h4 {
  font-weight: 600;
  font-size: 16px;
}

.center-dashboard .card-container .card p {
  font-weight: 300;
  font-size: 10px;
}