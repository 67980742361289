.loader-card {
    background: rgba(248, 248, 249, 0.5);
    border-radius: 10px;
    padding: 20px;
    font-weight: 500;
    font-size: 16px;
    width: 150px;
    height: 150px;
    animation: shimmer 2.5s infinite;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
}



@keyframes shimmer {
    100% {
        -webkit-mask-position: left
    }
}