main {
  display: grid;
  grid-template-columns: 23.5% 50% 23.5%;
  gap: 1.5%;
}

.search-main {
  display: grid;
  grid-template-columns: 23.5% 75%;
  gap: 1.5%;
}