/* License */
.licence-container-center {
    height: calc(100vh - 90px);
    background-color: #ffffff;
    border-top-left-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: scroll;
    padding-bottom: 0px;
}

.licence-container-center .sub-heading {
    margin-top: 20px;
}

.licence-container-center .check-list p {
    margin-top: 10px;
}

.licence-container-center .not-premitted-heading {
    margin-top: 30px;
}

.licence-container-center .longform-container {
    margin-top: 30px;
}

.licence-container-center .longform-container p a {
    color: gray;
}


.licence-container-center .longform-container .attribution-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    height: 60px;
    border-left: 2px solid #D1D1D1;
    background: rgba(248, 248, 249, 0.5);
}

.licence-container-center .longform-container .attribution-container p {
    margin-left: 10px;
    margin-right: 10px;
}

.licence-container-center .longform-container .attribution-container p a {
    color: gray;
}