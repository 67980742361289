.navigation-bar {
  height: 70px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.navigation-bar .logo-container {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.navigation-bar .logo-container svg {
  margin-right: 5px;
}

.navigation-bar .logo-container h1 {
  margin: 0px 5px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.navigation-bar .logo-container span {
  font-weight: 300;
  letter-spacing: 0.025em;
  text-transform: capitalize;
}

.navigation-bar .right-container-nav {
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #404040;
  display: flex;
  align-items: center;
}

.navigation-bar .right-container-nav span {
  margin: 0px 10px;
  color: #000000;
  text-transform: capitalize;
}

.navigation-bar .right-container-nav span:nth-child(2) {
  cursor: pointer;
}

.navigation-bar .right-container-nav img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-left: 10px;
}
