.right-dashboard .join-community {
  background-color: #fff;
  height: 160px;
  border-radius: 10px 0px 0px 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-dashboard .join-community span:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
}

.right-dashboard a {
  text-decoration: none;
  color: black;
}

.right-dashboard a:hover {
  color: #009B77;
}

.right-dashboard .recent-saved {
  height: calc(100vh - 270px);
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px 0 0 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}

.right-dashboard .recent-saved .head {
  font-weight: 300;
  font-size: 14px;
  color: #404040;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-dashboard .recent-saved .head span:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
  color: #000;
}

.right-dashboard .recent-saved .card {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px 12px 18px;
  background: rgba(248, 248, 249, 0.5);
  border-radius: 3px;
  margin-bottom: 20px;
  height: 95px;
  cursor: pointer;
}

.right-dashboard .recent-saved .card:hover {
  background: rgba(0, 155, 119, 0.05);
}

.right-dashboard .recent-saved .card .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-dashboard .recent-saved .card .info .name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000
}

.right-dashboard .recent-saved .card .info .tags {
  margin-top: 9px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #404040;
}


.right-dashboard .recent-saved .card .image {
  width: 49px;
  height: 49px;
}

.right-dashboard .recent-saved .card .image img {
  width: 100%;
  height: 100%;
}