@import url('https://fonts.googleapis.com/css2?family=Allura&family=Poppins:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #f8f8f9;
  font-family: "Poppins", sans-serif;
}

textarea {
  font-family: "Poppins", sans-serif;
}

input {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

button {
  font-family: "Poppins", sans-serif;
}

*::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}

#copy {
  position: absolute;
  top: -1000px;
}

.loader {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #ffd75f;
  /* Blue */
  height: 22px;
  width: 22px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 900px) {
  #root {
    display: none !important;
  }

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  body::after {
    content: "Please open this website on desktop";
    font-size: 16px;
    text-align: center;
    padding: 50px;
  }
}