.center-popup-error {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-popup-error .card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.center-popup-error .card button {
    width: 80px;
    height: 30px;
    margin-top: 15px;
    border: none;
    color: #fff;
    background-color: #009B77;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 0.02em;
    cursor: pointer;
}