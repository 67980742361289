.left-side-bar {
  height: calc(100vh - 90px);
  background-color: #fff;
  margin-top: 20px;
  border-top-right-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.left-side-bar .buttons {
   display: flex;
   flex-direction: column;
}

.left-side-bar .button-container {
  display: flex;
  align-items: center;
  height: 55px;
  margin-bottom: 10px;
  padding: 0px 10px;
  border-radius: 10px;
  cursor: pointer;
  max-height: 55px;
}

.left-side-bar .active {
  background: rgba(0, 155, 119, 0.05);
  color: #009B77;
  font-weight: 600;
}

.left-side-bar .button-container:hover {
  background-color: #f8f8f9;
}

.left-side-bar .active:hover {
  background: rgba(0, 155, 119, 0.05);
}

.left-side-bar .button-container .emoji {
  font-size: 24px;
  margin-left: 20px;
}

.left-side-bar .button-container .title {
  font-size: 18px;
  margin-left: 20px;
}

.left-side-bar .rotate {
  transform: rotate(180deg);
}

.left-side-bar img {
  cursor: pointer;
}