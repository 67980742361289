.center-popup-container {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-popup-container .card1 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.center-popup-container .card1 img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
    padding: 10px;
    background: rgba(196, 196, 196, 0.15);
}

.center-popup-container .card1 .info {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.center-popup-container .card1 .info h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.36px;
}

.center-popup-container .card1 .info p {
    margin-top: 5px;
    font-size: 12px;
    width: 230px;
}

.center-popup-container .card1 .info a {
    font-weight: 500;
    color: #808080;
}

.center-popup-container .card1 .info svg {
    margin-top: 15px;
    margin-right: 17px;
    cursor: pointer;
}

.center-popup-container .card1 .linkes-info {
    margin-top: 10px;
    width: 315px;
    height: 29px;
    border-radius: 4px;
    background: rgba(196, 196, 196, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

.center-popup-container .card1 .linkes-info span {
    font-size: 12px;
    letter-spacing: 0.24px;
}

.center-popup-container .card1 .linkes-info svg {
    margin-top: 0px;
    margin-right: 0px;
    cursor: pointer;
}

.center-popup-container .card1 .linkes-info a {
    color: #404040;
    font-weight: 400;
}

.center-popup-container .cross {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.center-popup-container .cross:hover path {
    fill: black;
}