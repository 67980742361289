.help-center {
  background-color: #fff;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-radius: 10px 10px 0px 0px;
}

.help-center a {
  height: 50px;
  min-height: 50px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  text-decoration: none;
  border-radius: 5px;
  color: #000000;
  font-size: 18px;
  letter-spacing: 0.02em;
  position: relative;
}

.help-center a::after {
  content: ">";
  position: absolute;
  right: 20px;
}

.help-center a:hover {
  background-color: #009B77;
  color: #fff;
}