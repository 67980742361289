.search-container-center {
  height: calc(100vh - 90px);
  background-color: #ffffff;
  border-top-left-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: scroll;
  padding-bottom: 0px;
}

.search-container-center .input-container {
  margin-top: 10px;
  width: 60%;
  height: 50px;
  position: relative;
  align-self: center;
  margin-bottom: 20px;
}

.search-container-center .input-container input {
  border: 2px solid #009B77;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  padding: 0px 40px;
}

.search-container-center .input-container img {
  position: absolute;
}

.search-container-center .input-container .search {
  left: 15px;
  top: 10px;
}

.search-container-center .input-container .cross {
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.search-container-center .scroll-container {
  height: calc(100vh - 90px);
  overflow: scroll;
  padding-bottom: 20px;
}

.search-container-center .card {
  background: rgba(248, 248, 249, 0.5);
  border-radius: 10px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  max-height: 150px;
  height: 150px;
  width: 150px;
  cursor: pointer;
}

.search-container-center .card img {
  width: 85%;
  height: 85%;
  object-fit: contain;
}

.search-container-center .card:hover {
  background: rgba(0, 155, 119, 0.05);
}

.search-container-center .card .download {
  display: none;
}

.search-container-center .card:hover .download {
  width: 90%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.search-container-center .card .download svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.search-container-center .card .download svg:hover path {
  stroke: #000000;
}

.search-container-center .no-result {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
}